@import url(https://fonts.googleapis.com/css?family=Mirza|Roboto&display=swap);
/* MIRZA */

@font-face {
  font-family: 'NotoUrdu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/NotoNastaliqUrdu-Regular.7a078f5d.ttf) format('truetype');
  unicode-range: U+0600-06FF;
}

@font-face {
  font-family: 'Nafees';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Nafees_Riqa_v1.0.10acf6bb.ttf) format('truetype');
  unicode-range: U+0600-06FF;
}

/* fix */
/* U+FDFA */
@font-face {
  font-family: 'FixSAW';
  font-style: normal;
  font-weight: 400;
  font-size: 'small';
  font-display: swap;
  src: url(/static/media/Nafees_Riqa_v1.0.10acf6bb.ttf) format('truetype');
  unicode-range: U+FDFA;
}

/* Mehr */
@font-face {
  font-family: 'Mehr-Nastaliq';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/MehrNastaliqWebRegular.260473bc.ttf) format('truetype');
  unicode-range: U+0600-06FF;
}

@font-face {
  font-family: 'Jamil';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Jameel_Noori_Nastaleeq_Regular.a1d0cd1f.ttf) format('truetype');
  /* unicode-range: U+0600-06FF; */
}

@font-face {
  font-family: 'JamilK';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Jameel_Noori_Nastaleeq_Kasheeda.163413e8.ttf) format('truetype');
  /* unicode-range: U+0600-06FF; */
}

@font-face {
  font-family: 'Bashar';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Bashar.20b8190b.TTF) format('truetype');
  /* unicode-range: U+0600-06FF; */
}

@font-face {
  font-family: 'Arbo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/ARBO-DT_1.7ee95446.ttf) format('truetype');
  /* unicode-range: U+0600-06FF; */
}

@font-face {
  font-family: 'ArboUW';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/ARBO_UW_0.c5c5ba76.ttf) format('truetype');
  /* unicode-range: U+0600-06FF; */
}

.my-font-class {
  /* font-family: 'Roboto', 'Mirza'; */
  /* font-family: 'Mirza', 'Roboto'; */
  /* font-family: 'NotoUrdu'; */
  /* font-family: 'Nafees'; */
  /* font-family: 'Nafees', 'Mirza', 'NotoUrdu', 'Roboto'; */
  /* font-family: 'FixSaw', 'Nafees', 'Mirza', 'NotoUrdu', 'Roboto'; */
  font-family: 'jamilk','Mehr-Nastaliq', 'FixSaw', 'Nafees', 'Mirza', 'NotoUrdu', 'Roboto';
}

.font-class-Bashar {
  font-family: 'Bashar', 'Mirza', 'Roboto';
}

.Arbic {
  font-family: 'Bashar', 'Mirza', 'Roboto';
}

.font-class-ARBO-DT-1 {
  font-family: 'Arbo', 'Bashar', 'Mirza', 'Roboto';
}

.font-class-ARBO-UW {
  font-family: 'ArboUW';
  letter-spacing: 0.2em;
  padding-left: 0.2em;
  /* word-spacing: 0.5em; */
}

/* .font-class-Jameel-Noori-Nastaleeq {
  font-family: 'FixSaw', 'Mehr-Nastaliq', 'Nafees', 'Mirza', 'NotoUrdu', 'Roboto';
} */

.font-class-Jameel-Noori-Nastaleeq {
  font-family: 'FixSaw', 'Jamil', 'Mehr-Nastaliq', 'Nafees', 'Mirza', 'NotoUrdu', 'Roboto';
}

/* Animation */

@keyframes fadeInUp {
  from {
      -webkit-transform: translate3d(0,40px,0);
              transform: translate3d(0,40px,0)
  }

  to {
      -webkit-transform: translate3d(0,0,0);
              transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      -webkit-transform: translate3d(0,40px,0);
              transform: translate3d(0,40px,0)
  }

  to {
      -webkit-transform: translate3d(0,0,0);
              transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animated-2 {
  animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.accordion-button.collapsed {
  /* color: white; */
  /* background: rgba(13,203,240,255); */

  /* font-size: '15vh'; */
  font-size: x-large;
  /* display: flex;
  align-content:center; */
}

.accordion-button:not(.collapsed) {
  /* color: black !important; */
  /* background-color: #e7f1ff; */
  color: white !important;
  background-color: #005380 !important;

}

.hadith-heading {
  font-size: x-large;
  font-family: 'JamilK';
  text-align: start;
}

.accordion-button .hadith-heading  {
  max-width: 95%;
}

.hadith-heading .font-class-Jameel-Noori-Nastaleeq {
  font-family: 'FixSaw', 'JamilK', 'Mehr-Nastaliq', 'Nafees', 'Mirza', 'NotoUrdu', 'Roboto';
  line-height: 1.7em;
}

.accordion-button::after {
  flex: 1 1 !important;
}

.accordion-button:not(.collapsed)::after {
  -webkit-transform: scaleY(-1) !important;
          transform: scaleY(-1) !important;
  background: url(/static/media/accordion-icon-white.19d76e3b.svg) !important;
  background-repeat: no-repeat !important;
}

.hadith-content {
  font-size: x-large;
}

.hadith-references {
  font-size: small;
}
/* .accordion-button.collapsed::after {
  font-size: x-large;
} */
.highlighted-text {
  /* color: red; */
  color: #F58221;
}

.hadith-search-div:hover{
  /* color: white; */
  background-color:lightgray;
}

.search-hadith-heading{
  color: #F58221;
  font-size: large;
}

.search-hadith-heading > span > p {
  margin-top: 5px;
  color: #212529;
}

.search-hadith-content{
  font-size: large;
}

/* .accordion-button:not(.collapsed) .highlighted-text {
  color: blue;
} */

.super-heading {
  background-color: #005380;
  color:white!important;
  margin-right:-9px;

}

/* nav bar */

.nav-bar-main {
  width: 100%;
  display: block!important;
}

.book-button {
  background-color: #005380!important;
  
}

.logo-main {
  width: 240px;
  display: block;
  float: left;
}

.nav-bar-main  a {
  display: inline-block;
  margin-top: 15px;
}

.dashboard-container {
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.userIcon {
  width: 50px;
  height: 40px;
  background: #ef7d08;
  border-radius: 4px;
  padding: 2px 2px;
}
.userIcon svg {

  fill: #ffffff;
}

.nav-link::after {
  top: 18px;
  position: absolute;
  left: 4px;
}

.userIcon .dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto;
}

.bg-overlay {
  background-color: rgb(0 0 0 / 33%);
  position:absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.ticker {
  white-space: nowrap;
}

.ticker-text {
  color: #F58221!important;
}

.upper-text .font-class-Jameel-Noori-Nastaleeq {
  font-family: 'FixSaw', 'JamilK'
}

.btn-new {
  background: #005380!important;
  font-family: "jamilk"!important;
}

.search-field {
  display: flex;
  flex-direction: column;
}

.search-field  .btn-group .search-btn  {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px 0px 0px 5px!important;
  background-color: #003866;
}

.search-field  .btn-group input {
  padding-left: 50px;
}

.search-btn {
  border-radius: 5px;
}

.note-text {
  font-size: 16px !important;
  margin-bottom: inherit;
  
}

.pagination-cls{
  direction: ltr;
  padding: 0 0 1rem 1rem;
}
/* Syncfusion */


/* === */
